export const API_URL = "https://api.coachmaster.io"; //https:/uat-api.coachmaster.io
export const BACKEND_URL = "https://manage.coachmaster.io"; //https://uat-manage.coachmaster.io
export const BLOG_URL = "https://blog.coachmaster.io"; //https://blog.coachmaster.io
export const COPYRIGHT_TEXT = `Copyright  © ${new Date().getFullYear()} Coachmaster.io, a Global Career Networks company. All Rights Reserved.`;
export const GBTW_URL = "https://app.getbacktowork.io/";
export const showPhonecountry = ['us'];
export const STRIPE_KEY = "pk_live_0kSfy0FdzZVwbzxVNsmApICh00d6L61Iyr";
export const COACHMASTER_URL = "https://coachmaster.io/";
export const CaptchaKey = "6LdnyO8lAAAAAGK-MFxWiEkYeirIOxJ3_RBcicEx";
export  const GOOGLE_CLIENT_ID = "528522524510-vqa3bdggg3jpaug1f2gt0keed0t48cjm.apps.googleusercontent.com";
export  const generatePassword = ()=>{
    let  length = 6;
    var charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var specialChars = "!@#$%^&*()-_+=<>?";
    var password = "";
    password += charset.charAt(Math.floor(Math.random() * charset.length)); // Add a random letter
    password += specialChars.charAt(Math.floor(Math.random() * specialChars.length)); // Add a random special character
    password += Math.floor(Math.random() * 10); // Add a random digit
    for (var i = 0; i < length - 3; i++) {
        password += charset.charAt(Math.floor(Math.random() * charset.length));
    }
    password = password.split('').sort(function() { return 0.5 - Math.random() }).join('');
    return password;
}
export const SHOW_FEED = true;
export const social_link = [
	{
		"name" : "LinkedIn",
		"class_name" : "fa fa-linkedin",
		"link" : "https://www.linkedin.com/company/coach-master/"
	},
	{
		"name" : "Facebook",
		"class_name" : "fa fa-facebook",
		"link" : "https://www.facebook.com/coach.master.io"
	},
	{
		"name" : "Instagram",
		"class_name" : "fa fa-instagram",
		"link" : "https://www.instagram.com/coachmaster_io/"
	},
	{
		"name" : "Youtube",
		"class_name" : "fa fa-youtube",
		"link" : "https://www.youtube.com/@coachmaster."
	},						
	{
		"name" : "Twitter",
		"class_name" : "fa fa-twitter",
		"link" : "https://twitter.com/CoachMaster_io"
	},
];

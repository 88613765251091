import { CorporateApp, MainApp } from "./Router";

export const APPS = [
    {
        subdomain: "www",
        app: MainApp,
        main: true
    },
    {
        //subdomain: "localhost",
        subdomain: "corporate.coachmaster.io",
        app: CorporateApp,
        main: false
    }
]

import React, { useState, useEffect } from "react";
import { menu } from "../../assets/images";
import { Link, NavLink,useLocation  } from "react-router-dom";
import TourSite from '../../utils/TourSite'
import axios from "../../config/axios";
import { Navbar, Nav, NavDropdown, Container, Row, Col } from 'react-bootstrap'

import { logo } from "../../assets/images";

let startTour = async () => {
  let userData = JSON.parse(localStorage.getItem('userdata'))

  await axios.post('/admin/tourStatusChange', { uid: userData.uid, status: 'active' })
  localStorage.setItem('tourActive', true)
  localStorage.setItem('tourStep', 0)
  window.location.replace('/profile-coach')
}




function Header() {



const [scrolled, setScrolled] = React.useState(false);
  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 200) {
      setScrolled(true);
    }
    else {
      setScrolled(false);
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
  })
  let navbarClasses = ['nav_wrap'];
  if (scrolled) {
    navbarClasses.push('sticky');
  }


  
  const location = useLocation();
  let showHeader = true;
  if(localStorage.getItem("authToken") !== null 
    && JSON.parse(localStorage.getItem("userdata")).account_type == 'coach' ){
      if(location.pathname == '/profile-coach' || location.pathname == '/profile-coach-edit'){
        showHeader = false;
      }
  }

  return (
    <div>
      {/* <TourSite startTour={tour}/> */}
      <section className="headerWrap">
        {/* <header className="nav_wrap" className={navbarClasses.join(" ")}>
           <div className="container"> */}
        <header className="nav_wrap"
        // className={navbarClasses.join(" ")}
        >


          <Container>
            <Navbar expand="md">
              <Navbar.Brand href="/">
                <img src={logo} alt="Coach Master" width={120} />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="navbarResponsive" className="navbar-toggler-right">
                <img alt="Coach Master" src={menu} width="20px" />
              </Navbar.Toggle>
              <Navbar.Collapse id="navbarResponsive">
                <div className="closeMenu" aria-controls="navbarResponsive">
                  <svg viewBox="0 0 507.273 507.273">
                    <g fill="#00e1ba">
                      <path d="M15.999 507.273a16 16 0 0 1-11.312-27.312L479.858 4.789a15.998 15.998 0 1 1 22.624 22.624L27.311 502.585a15.947 15.947 0 0 1-11.312 4.688z" />
                      <path d="M491.17 507.273a15.947 15.947 0 0 1-11.312-4.688L4.686 27.413c-6.18-6.259-6.149-16.334.071-22.553s16.295-6.251 22.554-.07l475.171 475.17a16 16 0 0 1-11.312 27.313z" />
                    </g>
                  </svg>
                </div>
                {showHeader &&
                <Nav className="ml-auto" as="ul">
                  <Nav.Item as="li">
                    <NavLink className="nav-link" exact={true} to="/">
                      Home
                    </NavLink>
                  </Nav.Item>
                  <Nav.Item as="li">
                    <NavLink className="nav-link" exact={true} to="/webinars-category">
                      Webinars
                    </NavLink>
                  </Nav.Item>

                  <Nav.Item as="li">
                    <NavLink className="nav-link" exact={true} to="/how-it-works">
                      How it works
                    </NavLink>
                  </Nav.Item>
                  <Nav.Item as="li">
                    <NavLink className="nav-link" exact={true} to="/pricing">
                      Pricing
                    </NavLink>
                  </Nav.Item>
                  <Nav.Item as="li">
                    <NavLink to="/categories" className="nav-link" exact={true}>
                      Categories
                    </NavLink>
                  </Nav.Item>
                  <Nav.Item as="li">
                    <NavLink className="nav-link" exact={true} to="/for-coaches">
                        Coaches
                    </NavLink>                  
                  </Nav.Item>
                  {/* <Nav.Item as="li">
                    <NavLink className="nav-link" exact={true} to="/for-business">
                        For Business
                    </NavLink>                  
                  </Nav.Item> */}
                  {/* <Nav.Item as="li">
                    <NavLink to="/findmy_coach" className="nav-link" exact={true}>
                      Search My Coach
              </NavLink>
                  </Nav.Item> */}

                  {/* <Nav.Item as="li">
                    <NavLink className="nav-link" to="/matchmy_coach" exact={true}>
                      Match my Coach
              </NavLink>
                  </Nav.Item> */}

                  {/* <Nav.Item as="li">
                    <Nav.Link href="http://blog.coachmaster.io" target='_blank'>
                      Blog
              </Nav.Link>
                  </Nav.Item> */}

                  <Nav.Item as="li">
                    <NavLink className="nav-link" to="/faq" exact={true}>
                      FAQs
                    </NavLink>
                  </Nav.Item>

                  <Nav.Item as="li">
                    <NavLink className="nav-link" to="/contact_us" exact={true}>
                      Contact Us
                    </NavLink>
                  </Nav.Item>

                  

                </Nav>
                }
              </Navbar.Collapse>
            </Navbar>
          </Container>
        </header>
      </section>
    </div>
  )
}

export default Header;

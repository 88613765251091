import React, { Component } from "react";
import { Dropdown } from "react-bootstrap";
import { logodark, user } from "../../assets/images";
import { SSO_LOGOUT } from "../../utils/Sso";
import axios from "../../config/axios";
import message from "../../utils/mesaages";
import "./webiner.css";

export default class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userdata: {},
    };
  }

  componentDidMount = () => {
    let userdata = localStorage.getItem("userdata");
    if (userdata) {
      this.setState({ userdata: JSON.parse(userdata) });
    } else {
      this.getProfile()
    }
  };

  logOutUser = async () => {
    const hideMessage = message.loading("Please wait while logging you out", 0);

    const token = localStorage.getItem("authToken");
    const usertoken = localStorage.getItem("corporate_user_token")

    if (usertoken) {
      axios.get(`/api/corporate_logout/${usertoken}`).then(async (response) => {
        hideMessage();
        if (response.data.success) {
          localStorage.clear()
          message.success(response.data.msg)
          this.setState({ loader: false });
          window.location = "/"
        }
      }).catch((error) => {
        hideMessage();
        message.error(error.response.data.msg);
        this.setState({ loader: false });
      });
    } else {
      await SSO_LOGOUT();
      localStorage.clear();
      if (token != undefined) {
        let param = {
          url: "/users/logout",
          method: "POST",
          data: { token: token },
          headers: { "X-Auth": token },
        };
        await axios(param);
      }
      hideMessage();
      window.location = "/login#logout";
    }

  };

  getProfile = async () => {
    this.setState({ loader: true });
    axios.get("/api/corprate_user_profile", { headers: { "X-Auth": localStorage.getItem("corporate_token") || localStorage.getItem("corporate_user_token"), }, }).then((res) => {
      if (res.data.success) {
        this.setState({ loader: false });
        this.setState({ userdata: res.data.data });
      }
    }).catch((err) => {
      console.log("err", err);
      this.setState({ loader: false });
      message.error("Something went wrong");
    });
  };

  render() {
    let { userdata } = this.state;
    let user2 = localStorage.getItem('userdata');
    let name = "";
    if(user2){
      let d = JSON.parse(user2);
       name = d.name.split(" ")[0];
    }
    return (
      <header className="coach_header d-flex justify-content-between bg-white fixed-top">
        <button className="toggleSidebar btn" onClick={this.props.handleClick}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            viewBox="0 0 48 48"
          >
            <path
              fill="#6f7380"
              d="M41 14H7a2 2 0 0 1 0-4h34a2 2 0 0 1 0 4ZM41 26H7a2 2 0 0 1 0-4h34a2 2 0 0 1 0 4ZM41 38H7a2 2 0 0 1 0-4h34a2 2 0 0 1 0 4Z"
            />
          </svg>
        </button>

        <div className="header_logo">
          <a href="/"><img src={logodark} width="130" height="52" className="img-fluid" /></a>
          <span style={{"margin-left":"2rem"}}>Welcome to Coachmaster Dashboard { name }</span>
          {/* <div className="mt-4">Welcome to Coachmaster Dashboard { name }</div> */}
        </div>
       
        {
          this.state.userdata.company_name && (
            <div className='organization_name'>
              <span>Organization Name : - <strong>{this.state.userdata.company_name}</strong></span>
            </div>
          )
        }

        <div className="header_tools d-flex">
          {/* <Dropdown className="notification_dropdown">
            <Dropdown.Toggle variant="default" className="rounded-0">
              <svg
                fill="#BCBCBC"
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="25"
              >
                <path d="M11.368 24.672a2.43 2.43 0 0 0 1.732-.699c.463-.454.729-1.076.74-1.729H8.897c.01.653.277 1.275.74 1.73a2.43 2.43 0 0 0 1.731.698Zm8.036-7.282v-6.675a7.908 7.908 0 0 0-1.755-4.928 7.76 7.76 0 0 0-4.43-2.718v-.85a1.882 1.882 0 0 0-.563-1.284 1.842 1.842 0 0 0-2.576 0 1.881 1.881 0 0 0-.563 1.284v.85a7.76 7.76 0 0 0-4.43 2.718 7.908 7.908 0 0 0-1.754 4.928v6.675L.86 19.817v1.214h21.015v-1.214l-2.472-2.427Z" />
              </svg>
              <span className="notification_count rounded-circle">02</span>
            </Dropdown.Toggle>
          </Dropdown> */}
          <Dropdown className="user_dropdown">
            <Dropdown.Toggle variant="default" className="rounded-0 header_img">
              <img width={100} height={100} src={userdata?.avataar || userdata.company_logo || user} className="img-fluid rounded-circle" alt={"Profile image"} /> {name}
              {/* <img width={100} height={100} src={userdata.avataar || userdata.company_logo || user} className="img-fluid rounded-circle" alt={userdata && userdata.name ? userdata.name : userdata.firstname + " " + userdata.lastname} /> {name} */}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={this.logOutUser} href="javascript:void(0)">
                Logout
              </Dropdown.Item>
              <Dropdown.Item  href="/">
                Homepage
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </header>
    );
  }
}
